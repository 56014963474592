export default {
    api_url: 'https://ekycbe.mynt.in/zebu',
    api_img: 'https://ekycbe.mynt.in',
    apipan: 'https://ekycbe.mynt.in/dd/',

    // api_url: 'https://ekycbe.mynt.in/uat/zebu',
    // api_img: 'https://ekycbe.mynt.in/uat/',
    // apipan: 'https://ekycbe.mynt.in/uatdd/',

    cams: 'https://rekycbe.mynt.in/cams',

    // apiDurl: 'http://192.168.5.81:5004'
    // https://ekycbe.mynt.in/uat
    //'https://zsync.zebull.in/zebu' 
}

var api_url = 'https://ekycbe.mynt.in/zebu';
// var api_url = 'http://192.168.5.81:5004/';


let myHeaders = new Headers();
var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: myHeaders
};
myHeaders.append("Content-Type", "application/json");

export async function getStartdata(m) {
    var response = await fetchMyntAPI(api_url + '/getstart?mob=' + m, requestOptions)
    return response
}

export async function getStartAction(item, type) {
    requestOptions['body'] = item
    var response = await fetchMyntAPI(api_url + '/getstart?mob=' + atob(localStorage.getItem('hash')) + `&type=${type}`, requestOptions)
    return response
}

async function fetchMyntAPI(path, requestOptions) {
    try {
        const response = await fetch(path, requestOptions);
        let data = await response.json();
        data = JSON.stringify(data);
        data = JSON.parse(data);
        return data
    } catch (error) {
        let data = 500
        return data
    }
}